import { gql, useQuery } from '@apollo/client';
import { Slider } from '@common';
import SkeletonComponent from '@components/common/Skeleton';
import Image from 'next/image';
import { useMemo } from 'react';

export const GET_BRANDS = gql`
  query getBrands {
    brandList {
      name
      url
      description
      image
    }
  }
`;

interface BrandListData {
  brandList: {
    name: string;
    url: string;
    description: string;
    image: string;
  }[];
}

interface BrandItemProps {
  url: string;
  name: string;
  image: string;
}

const urlRegex = /(.html)(\D|\d)*/g;
const BrandItem = ({ url, name, image }: BrandItemProps) => {
  const newUrl = useMemo(() => {
    return url.replace(urlRegex, '/c');
  }, [url]);

  return (
    <a
      href={newUrl}
      className="mx-auto min-w-[112px] lg:min-w-[160px] max-w-[112px] lg:max-w-[160px] p-4 flex items-center justify-items-center h-[88px] lg:h-[120px] rounded border border-gray-light bg-white shadow-[_0px_8px_16px_-4px_rgba(7,_54,_101,_0.04)]"
    >
      <div className="relative h-full w-full">
        <Image
          alt={name}
          src={process.env.NEXT_PUBLIC_SERVICE + image}
          className="object-contain"
          fill
        />
      </div>
    </a>
  );
};

export const BrandsSlider = () => {
  const { data, loading } = useQuery(GET_BRANDS, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-only'
  });

  const brandList = useMemo(
    () =>
      (data?.brandList as BrandListData['brandList'])?.filter(
        brand => brand.image !== 'false'
      ) || [],
    [data?.brandList]
  );

  if (loading && !brandList.length) {
    return (
      <section className="my-6 w-full flex flex-col justify-center items-center">
        <SkeletonComponent width="12rem" height="28px" />

        <div className="w-full px-4 mt-4 gap-4 flex flex-row overflow-x-scroll">
          {Array.from({ length: 7 }).map((_, index) => (
            <SkeletonComponent width="12rem" height="7.5rem" key={index} />
          ))}
        </div>
      </section>
    );
  }

  return (
    <>
      {brandList.length > 0 && (
        <section className="my-6 px-4 lg:px-auto">
          <article>
            <div className="container mx-auto">
              <h2 className="text-center font-bold text-lg lg:text-xl text-primary-dark dark:text-primary-light mb-4 lg:mb-5">
                Nossas marcas
              </h2>
            </div>
            <div
              style={{
                gridTemplateColumns: `repeat(${brandList.length}, minmax(112px, 1fr))`
              }}
              className="container mx-auto grid gap-4 lg:hidden overflow-x-auto grid-rows-1 px-3"
            >
              {brandList.map(brandItemProps => (
                <BrandItem key={brandItemProps.name} {...brandItemProps} />
              ))}
            </div>
            <div className="container mx-auto hidden lg:block">
              <Slider
                showDots={false}
                autoPlaySpeed={9000}
                itemClass="ml-4"
                numberOfSlides={7}
                customArrow="dark:bg-[#009EE0]"
                customArrowColor="dark:text-white-lightest"
              >
                {brandList
                  .concat(brandList)
                  ?.map(brandItemProps => (
                    <BrandItem key={brandItemProps.name} {...brandItemProps} />
                  ))}
              </Slider>
            </div>
          </article>
        </section>
      )}
    </>
  );
};
